/*
 * Date: 2024
 * Description: Mail form
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Typography , Box , Radio , RadioGroup , FormControlLabel , FormLabel } from '@mui/material';
import React, { useState , useContext , useEffect, ReactElement } from 'react';
import { LoadingButton } from '@mui/lab';

//Components
import CustomInput from './custom.input';
import PromptAlert from '../utils/prompt.alert';

//Interfaces
import { i_alert_props , i_error_input, i_prompt_alert_props } from '../../interface/utility.interface';
import { i_send_smtp } from '../../interface/smtp.interface';

//Styles
import { field_style } from '../styles/styles';

//Validation
import { m_valid_address, m_valid_email } from '../utils/field.validation';

//Utils
import { Fetch } from '../api/fetch';

//Context
import { ManagementContext } from '../../context/management.context';
import { default_alert } from '../../utility/constant';

const MailForm = ( props : any ) : ReactElement => {
    const { smtp_config , setNewSmtpConfig} = useContext(ManagementContext)
    const [ id , setId ] = useState<number>(0)
    const [ username , setUsername] = useState<string>('')
    const [ password , setPassword] = useState<string>('')
    const [ from , setFrom ] = useState<string>('')
    const [ host , setHost ] = useState<string>('')
    const [ secure , setSecure ] = useState<number>(0)
    const [ pool , setPool ] = useState<number>(0)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert_obj , setAlertObj ] = useState<i_alert_props>({msg : '' , severity : 'error' , open : false, position : 'modal'});
    const [ err_username , setErrUsername ] = useState<i_error_input>({error : false , msg : ''});
    const [ err_password , setErrPassword ] = useState<i_error_input>({error : false , msg : ''});
    const [ err_from , setErrFrom ] = useState<i_error_input>({error : false , msg : ''})
    const [ err_host, setErrHost ] = useState<i_error_input>({error : false , msg : ''});
    const handleAlertreset = () => {
        setAlertObj({msg : '' , severity : 'error' , open : false ,position : 'modal'})
    }
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        if(err_password) setErrPassword({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
        if(err_username) setErrUsername({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeHost = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHost(event.target.value)
        if(err_host) setErrHost({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeFrom = ( event: React.ChangeEvent<HTMLInputElement>) => {
        setFrom(event.target.value)
        if(err_from) setErrFrom({ error : false , msg : ''})
        handleAlertreset();
    }
    const handleChangeSecure = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecure(Number((event.target as HTMLInputElement).value))
    }
    const handleChangePool = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPool(Number((event.target as HTMLInputElement).value))
    }
    useEffect(() => {
        setId(smtp_config.id)
        setHost(smtp_config.host)
        setPassword(smtp_config.password)
        setUsername(smtp_config.username)
        smtp_config.pool ? setPool(1) : setPool(0)
        smtp_config.secure ? setSecure(1) : setSecure(0)
        setFrom(smtp_config.from)
    },[smtp_config])
    const handleSubmit = async(event : any) => {
        event.preventDefault();
        var secure_check = false;
        if(secure === 1) secure_check = true;
        var pool_check = false;
        if(pool === 1) pool_check = true;
        const data : i_send_smtp = {
            id : id,
            host : undefined,
            username : undefined,
            password : undefined,
            secure :secure_check,
            pool : pool_check,
            from : undefined
        }
        const valid_host = m_valid_address(host)
        if(valid_host !== '') return setErrHost({ error : true , msg : valid_host })
        data.host = host
        const valid_username = m_valid_address(username)
        if(valid_username !== '') return setErrUsername({ error : true , msg : valid_username })
        data.username = username
        const valid_password = m_valid_address(password)
        if(valid_password !== '') return setErrPassword({ error : true , msg : valid_password })
        data.password = password
        const valid_from = m_valid_email(from)
        if(valid_from !== '') return setErrFrom({ error : true , msg : valid_from})
        data.from = from
        setLoading(true)
        const output = await Fetch('/api/mail/config' , data , 'PUT') 
        setLoading(false)
        if(output.type === 'Success'){
            setNewSmtpConfig(output.data)
            props.setAlertObj({msg : output.message , severity : 'success' , open : true, position:'page'})
            props.handleClose()
        }else{
            setAlertObj({msg : output.message , severity : 'error' , open : true ,position:'modal'})
        }
       
    }
    const prompt_alert_props : i_prompt_alert_props = {
        ...alert_obj,
        reset : () => setAlertObj(default_alert)
    }
    return (
        <div>
            <Typography>Mail form</Typography>
            <Box component={'form'} onSubmit={handleSubmit} autoComplete='off'>
                {alert_obj.open ? <PromptAlert {...prompt_alert_props}></PromptAlert> : null}  
                <CustomInput value={username} onChange={handleChangeUsername} err={err_username} name={'Mail box user'} type={'text'} style={field_style} required={true} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={password} onChange={handleChangePassword} err={err_password} name={'Mail box password'} type={'password'} style={field_style} required={true} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={host} onChange={handleChangeHost} err={err_host} name={'Mail box host'} type={'text'} style={field_style} required={true} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={from} onChange={handleChangeFrom} err={err_from} name={'Mail box from'} type={'text'} style={field_style} required={true} fullWidth={true} complete={'off'}></CustomInput>
                <FormLabel>Secure</FormLabel>
                <RadioGroup name="radio-buttons-group-secure" onChange={handleChangeSecure} value={secure} row>
                    <FormControlLabel value={1} control={<Radio />} label="On ( port 465 )" />
                    <FormControlLabel value={0} control={<Radio />} label="Off ( port 587 )" />
                </RadioGroup>
                <FormLabel>Pool connection</FormLabel>
                <RadioGroup name="radio-buttons-group-pool" onChange={handleChangePool} value={pool} row>
                    <FormControlLabel value={1} control={<Radio />} label="On" />
                    <FormControlLabel value={0} control={<Radio />} label="Off" />
                </RadioGroup> 
                <LoadingButton type="submit" fullWidth variant="contained" color='primary' loading={loading} sx={{ mt: 3, mb: 2 }}>Submit</LoadingButton> 
            </Box>
        </div>
 
    )
}
export default MailForm