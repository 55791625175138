/*
 * Date: 2025
 * Description: Transition page , that shows a loading on token validation.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Container , Box , CircularProgress } from "@mui/material";

//Themes
import { ThemeProvider } from "@mui/private-theming";
import { c_theme } from "../theme/custom.theme";
//Interfaces
import { i_inital_props } from "../interface/utility.interface";

const Welcome = ( props : i_inital_props) => {
    const nav = useNavigate();
    useEffect(() => {
        if(props.loaded) nav('/login')
    },[props , nav])
    return (
        <ThemeProvider theme={c_theme}>
            <Container style={{height : '100vh'}} component="main" maxWidth="xs">
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size="7.5em" sx={{color : "#d9b451",marginLeft:'auto' , marginRight:'auto', marginTop:'42.5vh'}}/>
            </Box>
            </Container>
        </ThemeProvider>
    )
}
export default Welcome