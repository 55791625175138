/*
 * Date: 2024
 * Description: Space for smtp configuration
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Typography , Paper , IconButton} from "@mui/material"
import { ReactElement, useContext , useState } from 'react'
import UpdateIcon from '@mui/icons-material/Update';
import { LoadingButton } from "@mui/lab";

//Functions
import { Fetch } from "../api/fetch";
import { delay } from "../../utility/utility";

//Context
import { ManagementContext } from "../../context/management.context"
import { MainContext } from "../../context/main.context";


const SmtpPaper = ({HandleModal} : any) : ReactElement => {
    const { smtp_config } = useContext(ManagementContext)
    const { user } = useContext(MainContext)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ response , setResponse ] = useState<any>({ event : false , msg : '' , severity : 'Failed' })

    const handleTestConfig = async() => {
        handleClearResponse();
        setLoading(true)
        const f_fetch_address = async() => {
            await delay(1000)
            const output = await Fetch('/api/mail/test/'+user.email,null,'GET' )
            if(output.type === 'Success'){
                setResponse({ event : true , msg : output.message , severity : 'Success'})
            }else{
                setResponse({ event : true , msg : output.message , severity : 'Failed'})
            }
            setLoading(false)
        }
        f_fetch_address();
    }
    const handleClearResponse = () => {
        setResponse({ event : false , msg : '' , severity : 'Failed'})
    }
    const wrap = {
        padding : '0.7em', display : 'flex'
    }
    const first_row = {
        display : 'flex' ,  width : '50%' , fontSize:18
    }
    return (
        <Paper style={{ padding : '2em' }} elevation={5}>
            <div style={wrap}>
                <Typography component={'div'} style={{ fontSize:22 , textDecoration : 'underline'}}>Smtp config</Typography><IconButton onClick={() => HandleModal(smtp_config,'update')}><UpdateIcon color={'primary'}></UpdateIcon></IconButton>
            </div>
            <div style={wrap}>
                <Typography component={'div'} style={first_row}>Host : 
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>{smtp_config.host}</Typography>
                </Typography>
                <Typography component={'div'} style={{ display : 'flex' ,  fontSize:18}}>Username : 
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>{smtp_config.username}</Typography>
                </Typography> 
            </div>
            <div style={wrap}>
                <Typography component={'div'} style={first_row}>Password : 
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>************</Typography>
                </Typography>
                <Typography component={'div'} style={first_row}>From :  
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>{smtp_config.from}</Typography>
                </Typography>
            </div>
            <div style={wrap}>
                <Typography component={'div'} style={first_row}>Pool : 
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>{smtp_config.pool === true ? 'On' : 'Off'}</Typography>
                    </Typography>                 
                <Typography component={'div'} style={first_row}>Secure (port 587/465) : 
                    <Typography style={{ alignSelf : 'center' , fontSize:18 , marginLeft: '10px' , fontWeight : 900}}>{smtp_config.secure === true ? 'On' : 'Off'}</Typography>
                </Typography>
            </div>
            <div style={wrap}>
                <LoadingButton style={{ fontWeight : 900}} loading={loading} variant={'contained'} color={'primary'} onClick={() => handleTestConfig()} >Test Config</LoadingButton>
                {response.event ? <Typography onClick={() => handleClearResponse()} style={{ color : response.severity === 'Success' ? 'green' : 'red' , alignSelf:'center' , marginLeft : '2em' , border : '1px solid black' , padding : '0.3em' , cursor : 'pointer'}}>{response.msg}</Typography> : null}
            </div>
        </Paper>
    )
}
export default SmtpPaper