/*
 *
 * Filename: filter.table.pagination.tsx
 * Date: 2025
 * Description: Custom pagination component handle min max next back page , show current position. And also handle Rows per page
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { MouseEvent } from "react";
import { IconButton , Box, Typography, TextField , MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';

//Intefaces
import { i_table_pagination_props } from "../../interface/utility.interface";

//Utility
import { ItemPerPage } from "../../utility/constant";

//Styles
import { selected_style , table_btn_style } from "../../styles/table";

//Icons
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

const BottomPagination = ( props : i_table_pagination_props ) => {
    const f_format_page_display = () => {
        var min : number = 1;
        var max : number = rowsPerPage
        if(page > 0){
            min = (page * rowsPerPage) + 1
            max = (page * rowsPerPage) + rowsPerPage + 1
            if(max > count)  max = count
        }
        return min + " / " + max
    }
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const totalPages = Math.ceil(count / rowsPerPage);
    const startPage = Math.max(1, page - 3); 
    const endPage = Math.min(totalPages, page + 5); 
    const handleFirstPageButton = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>,) => onPageChange(event, 0);
    const handlePageChange = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>, new_page : number ) => {
        onPageChange(event, new_page);
    }
    const handleLastPageButton = (event: MouseEvent<HTMLButtonElement>) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    return (
        <Box sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh' }}>
            <Typography sx={{ fontSize : 14 , alignSelf : 'center' , marginRight: '1vh' }}>Rows per page:</Typography>
            <TextField select value={Number(props.search.offset)} onChange={props.handleChangeRowsPerPage} sx={{ marginRight : '1vh' , fontSize : 12 }}>
                {ItemPerPage.map((option : any, i : number) => ( <MenuItem key={i} value={option.value}> {option.option} </MenuItem> ))}
            </TextField>
            <Typography sx={{ fontSize : 14 , alignSelf : 'center' ,  marginRight: '1vh'}}>{f_format_page_display()} of {count}</Typography>
            <Typography></Typography>
            <Box sx={{ alignSelf : 'center'}}>
                <IconButton onClick={handleFirstPageButton} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                      {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                        const pageNumber = startPage + i;
                        return (
                          <Box key={pageNumber} sx={pageNumber === page + 1 ? selected_style : table_btn_style} onClick={(e :MouseEvent<HTMLDivElement>) => handlePageChange(e, pageNumber - 1)}>
                            {pageNumber}
                          </Box>
                        );
                      })}
                <IconButton onClick={handleLastPageButton}disabled={page >= Math.ceil(count / rowsPerPage) - 1}aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
       </Box>
    )
}

export default BottomPagination;