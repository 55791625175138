/*
 * Date: 2025
 * Description: Main context set up
 * Author: Philippe Leroux @SKITSC
 *
 */

import { createContext } from 'react'
import { i_user } from '../interface/user.interface';
import { default_user } from "../utility/constant";


//Context interface
export interface i_main_context {
    user : i_user,
    authenticated : boolean,
    loaded : boolean,
    setUser : ( { first_name , last_name , email , id , UUID , type , phone , password } : i_user) => void;
    setCurrentAuth : (currentAuth : boolean) => void;
    HandleLogin : (currentUser : i_user ) => void;
    HandleLogout : () => void;
    setLoaded : (CurrentLoaded : boolean) => void;
}
export interface i_auth_context {
    authenticated : boolean,
    setCurrentAuth : ( currentAuth : boolean ) => void;
}
export const DefaultContext : i_main_context = {
    user : default_user,
    authenticated : false,
    loaded : false,
    setUser : () => {},
    setCurrentAuth : () => {},
    HandleLogin : () => {},
    HandleLogout : () => {},
    setLoaded : () => {},
}

export const MainContext = createContext<i_main_context>(DefaultContext)