/*
 * Date: 2024
 * Description: Page where the user can filters and navigate threw all records.
 * Author: Philippe Leroux @ SKITSC
 *
 */
//Majors modules
import { useState , useContext , useEffect , useRef , useMemo , MouseEvent, ReactElement , ChangeEvent } from 'react'
import { SocketContext } from '../context/socket.context';
import { ThemeProvider , Paper , Box, IconButton } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import { c_theme } from "../theme/custom.theme"
import ReactAudioPlayer from 'react-audio-player';
import { Dayjs } from 'dayjs';

//Components && imports.
import PagesComponent from "../components/table/fitler.table.pagination";
import TableSSR from "../components/table/filter.table.records";
import Filters from "../components/table/top.bar.filter.records";
import PromptAlert from '../components/utils/prompt.alert';
import Bubble from '../components/utils/bubble';
import ModalForm from '../components/modal/modal';

//Interfaces && types
import { i_records, i_records_tbl_props } from '../interface/records.interface';
import { i_filter_records } from "../interface/records.interface";
import { i_note_form_props , i_contact, i_inital_props , i_modal_props, i_tbl_filter_props , i_prompt_alert_props } from '../interface/utility.interface';
import { i_alert_props } from '../interface/utility.interface';
import { i_endpoint } from '../interface/endpoint.interface';

//Constants
import { default_alert, default_search , default_target } from '../utility/constant';
import { MainContext } from '../context/main.context';
import { Fetch, Fetch_File } from '../components/api/fetch';
import { f_format_phone_cute, f_timestamp_to_date , findEndpoint , handlePhone } from "../utility/utility";

//Middlewares
import { validateNote } from '../middlewares/main.middleware';

//Icons
import CloseIcon from '@mui/icons-material/Close';

const default_delete_title : string = 'Are you sure to delete record with the id : '
const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return screenWidth;
};
const Records = ( props : i_inital_props ) : ReactElement => {

    const { HandleLogout , user } = useContext(MainContext)    
    const socket = useContext(SocketContext)
    const nav = useNavigate()
    const [ src , setSrc ] = useState<string>('')
    const [ search , setSearch ] = useState<i_filter_records>(default_search)
    const [ data , setData ] = useState<i_records[]>([])
    const [ endpoints , setEndpoints ] = useState<i_endpoint[]>([])
    const [ note , setNote ] = useState<string>('')
    const [ note_error , setNoteError ] = useState<string>('')
    const [ count , setCount ] = useState<number>(0)
    const [ open , setOpen ] = useState<boolean>(false)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ target , setTarget ] = useState<i_records>(default_target)
    const [ isPlaying , setIsPlaying ] = useState<boolean>(false)
    const [ title , setTitle ] = useState<string>('')
    const [ sub_title, setSubTitle ] = useState<string>('')
    const [ searchFrom , setSearchFrom ] = useState<string>('')
    const [ searchTo , setSearchTo ] = useState<string>('')
    const [ event , setEvent ] = useState<'delete_record' | 'edit_record'>('delete_record')
    const [ alert_obj , setAlertObj ] = useState<i_alert_props>(default_alert);
    const [ fetch_loading , setFetchLoading ] = useState<boolean>(false)
    const [ fromArr , setFromArr ] = useState<i_contact[]>([])
    const [ toArr , setToArr ] = useState<i_contact[]>([])
    const [ bubble , setBubble ] = useState<boolean>(false)
    const [ new_event , setNewEvent ] = useState<i_records>(default_target)

    const handlePlay = ( row : i_records ) => {
        const true_path = process.env.REACT_APP_API_URL+'/api/file/'+row.add_time+'/'+row.path
        setTarget(row)
        setSrc(true_path)
        handlePlayPause()
    } 
    const handleClose = () => setOpen(false)
    useEffect(() => {
        if(user.type === 'Admin'){
            socket.removeAllListeners("new_call");
            socket.on('new_call', ( row ) => {
                if(search.pages === 0 && search.timestamp === 'DESC'){
                    for(var x :number = 0; x < row.returned_rows.length; x++) {
                        const data_to_add = [ ...data ];
                        const objectExists = data_to_add.some((obj: i_records) => obj.call_uuid === row.call_uuid);
                        if (!objectExists) {
                            if (data_to_add.length > 0)  data_to_add.pop()
                            data_to_add.unshift(row.returned_rows[x]);
                            setData(data_to_add);
                            var new_count : number = count + 1
                            setCount(new_count)
                            setNewEvent(row.returned_rows[x])
                            setBubble(true)
                        }    
                    }
                }
            })
        }
        socket.removeAllListeners("call_notes");
        socket.on('call_notes', ( row ) => {
            const item = row.item
            if(row.type === 'Add' || row.type === 'Update'){
                const data_to_update = [ ...data ] 
                const updatedItems = data_to_update.map( ( prev_item : i_records , i : number) => {
                    if (prev_item.id === item.data.id) {
                        if(target.id === item.data.id) {
                            setTarget(item.data)
                        }
                        return item.data
                    } else {
                        return prev_item
                    }
                })
                setData(updatedItems)
            }
        })
    },[data , socket, search , count , target , user])
    useEffect(() => {
        const fetchEndpoints = async () => {
            const res = await Fetch('/api/endpoints' ,null, "GET")
            if(res.type === "Success") {
                setEndpoints(res.data)
                return res.data
            }else{
                if(res.type === 'Unauthorized'){
                    props.logout()
                }else{
                    setAlertObj({msg : res.message , severity : 'error' , open : true ,position : 'page'})
                    return []
                }
            }
        }
        const fetchContacts = async ( E : i_endpoint[]) => {
            const res = await Fetch('/api/records/contacts' ,null, "GET")
            if(res.type === "Success") {
                const formated_from = []
                const formated_to = []
                for(var x =0; x < res.data.from.length; x++){
                    if(res.data.from[x].number !== 'none' && res.data.from[x].number !== null){
                        const num = findEndpoint(res.data.from[x].number , 'in' , E)
                        if(num.length ===0) console.log(num)
                        formated_from.push({ number : num, count : res.data.from[x].count })
                    }
                }
                setFromArr(formated_from)
                for(var y : number =0; y < res.data.to.length; y++){
                    if(res.data.to[y].number !== 'none' && res.data.to[y].number !== null){
                        formated_to.push({ number : res.data.to[y].number, count : res.data.to[y].count })
                    }
                }
                setToArr(formated_to)
            }
            setLoading(false)
        }
        const main = async() => {
            if(user.type === 'Admin'){
                const E = await fetchEndpoints()
                await fetchContacts(E)
            }
        }
        main()
    },[props , nav , HandleLogout , user])
    function removeSpecialChars(inputString: string): string {
        var clean = inputString.replace(/[-()]/g, '');
        return clean
      }
    useEffect(() => {
        setLoading(true)
        const f_fetch_rows = async () => {
            const f_encodeQueryData = () =>  { return new URLSearchParams({pages : search.pages.toString() , note : search.note.toString() ,
                offset : search.offset.toString() , from_number : removeSpecialChars(search.from_number) ,to_number :  removeSpecialChars(search.to_number),
                 timestamp : search.timestamp , start : search.date_range.start.toString(), end : search.date_range.end.toString() }) }
            const res = await Fetch('/api/records/filtered?'+f_encodeQueryData() ,null, "GET")
            if(res.type === "Success") {
                setData(res.data.records)
                setCount(res.data.count)
            }else{
                if(res.type === 'Unauthorized')props.logout()
            }
            setLoading(false)
        }

        f_fetch_rows()
    },[search , props , HandleLogout , nav])
    useEffect(() => {
        if(note_error !== '' && note.length > 0) setNoteError('') 
    },[note , note_error])
    const handleAction = async() => {
        setFetchLoading(true)
        const f_api_delete = async(row : i_records) => {
            const output = await Fetch('/api/records/'+row.id , null , 'DELETE' )
            if(output.type === 'Success'){
                var current_rows : Array<i_records> = [...data]
                const desactivated_row = current_rows.findIndex( i => i.id === row.id)
                current_rows.splice(desactivated_row,1)
                setData(current_rows)
                handleClose();
                setAlertObj({msg : 'Record id : ' + row.id + ' desactivated.' , severity : 'success' , open : true, position : 'page'})
            }
        }
        const f_api_update = async(row : i_records) => {
            const new_note = row
            new_note.note = note
            const updated_row = await Fetch('/api/records/note' , new_note , 'POST' )
            if(updated_row.type === 'Success'){
                setNote('')
            }
            if(updated_row.type !== 'Success'){
                setAlertObj({msg : 'Note id : ' + row.id + ' updated.', severity : updated_row.type === "Failed" ? 'error' : 'warning', open : true ,position : 'page'})
            }
        }
            
        if(event === 'delete_record')await f_api_delete(target)
        if(event === 'edit_record') {
            const [ valid , error ] = validateNote( note )
            if(valid){
                await f_api_update(target)
            }else{
                setNoteError(error)
                setFetchLoading(false)
                return
            }
            
        }else
        if(open) setOpen(false)
        setFetchLoading(false)
    }
    const handleDownload = async(row : i_records) => {
        const res = await Fetch_File('/api/file/'+row.add_time+'/'+row.path , "GET")
        if(res !== undefined){
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = "D-"+f_timestamp_to_date(row.add_time , 'short') + '-F-' + f_format_phone_cute(row.from_number) + '-T-' + f_format_phone_cute(row.to_number) + '.mp3';
            a.href = url;
            document.body.appendChild(a);
            a.click();
        }
    }
    const setDelete = ( row : i_records ) : void => {
        setTarget(row)
        setEvent('delete_record')
        setTitle(default_delete_title+row.id)
        setOpen(true)
    }
    const handleChangePage = (event: MouseEvent<HTMLButtonElement | HTMLDivElement> | null, newPage: number) : void => { 
        const new_search : i_filter_records = {...search}
        new_search.pages = newPage
        setSearch(new_search)
    };
    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
    const new_search : i_filter_records = {...search}
    new_search.pages = 0
    new_search.offset = parseInt(event.target.value, 10)
    setSearch(new_search)
    };
    const handleEdit = (row : i_records) => {
        setTarget(row)
        setNote('')
        setEvent('edit_record')
        setTitle("Notes for ID :"+row.id)
        setSubTitle("From : " +handlePhone(row.from_number , endpoints) + " -- To : " + f_format_phone_cute(row.to_number) + " -- Date : " + f_timestamp_to_date(row.add_time , 'long'))
        setOpen(true)
    }
        
    const audioRef = useRef<ReactAudioPlayer>(null);
    const handlePlayPause = () => {
        if (audioRef.current) {
            const audioElement = audioRef.current.audioEl.current;
            if (isPlaying) {
            audioElement !== null && audioElement.pause();
            } else {
            audioElement !== null && audioElement.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    const handleAudioPlay = () : void => setIsPlaying(true);
    const handleAudioPause = () : void =>  setIsPlaying(false);
    const handleTimestamp = () : void => {
        const current_search = {...search}
        if(search.timestamp === 'ASC'){
            current_search.timestamp = 'DESC'
            setSearch(current_search)
        }else{
            current_search.timestamp = 'ASC'
            setSearch(current_search)
        }
    }
    const clearFilter = () : void => {
        const new_search : i_filter_records = {...default_search}
        new_search.date_range.start = 0;
        new_search.date_range.end = 0;
        setSearch(new_search)
    } 
    const handleChangeFrom = (value : string) => {
        const current_search : i_filter_records = {...search}
        current_search.pages = 0
        value.length > 0 ? current_search.from_number = findEndpoint(value , 'out' , endpoints) : current_search.from_number = ''
        setSearch(current_search)
    }
    const handleChangeTo = (value : string) => {
        const current_search : i_filter_records = {...search}
        current_search.to_number = value
        current_search.pages = 0
        setSearch(current_search)
    }
    const handleChangeNotes = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const current_search : i_filter_records = {...search}
        current_search.note = event.target.value
        setSearch(current_search)
    }
    const handleChangeDate = ( value :  Dayjs | null , type : 'start' | 'end') => {
        const current_search : i_filter_records = {...search}
        const timestamp = value ? value.valueOf() : null; 
        if(timestamp === null) return
        if(type ==='start'){
            current_search.date_range.start = Number(timestamp)
        }else{
            current_search.date_range.end = Number(timestamp)
        }
        setSearch(current_search)
    }
    const handleBubble = ( ) : void => {
        setBubble(false)
        setOpen(true)
        setTarget(new_event)
        setEvent('edit_record')
        setTitle("Set a note for the new call")
        setSubTitle("From : " +handlePhone(new_event.from_number , endpoints) + " -- To : " + f_format_phone_cute(new_event.to_number) + " -- Date : " + f_timestamp_to_date(new_event.add_time , 'long'))
    }
    const handleAudioClose = () : void => {
        setIsPlaying(false)
        setSrc('')
    }
    const containsText = (text : string, search : string) =>  text.toLowerCase().indexOf(search.toLowerCase()) > -1 
    const displayedOptionsFrom = useMemo( () =>
            fromArr.filter((option: i_contact) => {
                if (!option || !option.number) return false;
                if (searchFrom.includes('*')) {
                    const prefix = searchFrom.replace('*', '');
                    return option.number.startsWith(prefix);
                }
                return containsText(option.number, searchFrom);
            }),
        [searchFrom, fromArr]
    );
    const displayedOptionsTo = useMemo( () =>
            toArr.filter((option: i_contact) => {
                if (!option || !option.number) return false;
                if (searchTo.includes('*')) {
                    const prefix = searchTo.replace('*', '');
                    return option.number.startsWith(prefix);
                }
                return containsText(option.number, searchTo);
            }),
        [searchTo, toArr]
    );

    const tbl_props : i_records_tbl_props = {
        data : data,
        endpoints : endpoints,
        search : search,
        count : count,
        loading : loading,
        setDelete : setDelete,
        handleEdit : handleEdit,
        handlePlay : handlePlay,
        handleDownload : handleDownload,
        src : src,
        target : target,
        isPlaying : isPlaying,
        handleTimestamp : handleTimestamp,
        clearFilter : clearFilter,
        handleClose : handleClose,
    }
    const tbl_filter_props : i_tbl_filter_props= {
        endpoints : endpoints,
        search : search,
        handleTimestamp : handleTimestamp,
        clearFilter : clearFilter,
        handleChangeTo : handleChangeTo,
        handleChangeFrom : handleChangeFrom,
        handleChangeNote : handleChangeNotes,
        handleChangeDate : handleChangeDate,
        searchFrom : searchFrom,
        searchTo : searchTo,
        setSearchFrom : setSearchFrom,
        setSearchTo : setSearchTo,
        displayedOptionsFrom : displayedOptionsFrom,
        displayedOptionsTo : displayedOptionsTo,
    }
    const note_form_props : i_note_form_props = {
        title : title,
        sub_title : sub_title,
        note : note,
        target : target,
        error : note_error,
        setError : setNoteError,
        setNote : setNote,
        loading : fetch_loading,
        callback : handleAction,
    }

    const modal_props : i_modal_props = {
        open : open,
        event: event,
        data : bubble ? new_event : target,
        action : handleAction,
        title : title,
        loading : fetch_loading,
        handleClose : handleClose,
        item_props : note_form_props,
        new_close : false,
    }
    const screenWidth = useScreenWidth();
    const bubble_props = {
        bubble : bubble,
        callback : handleBubble,
    }
    const prompt_alert_props : i_prompt_alert_props = {
        ...alert_obj,
        reset : () => setAlertObj(default_alert)
    }
    return (
        <ThemeProvider theme={c_theme}>
            {alert_obj.open && <PromptAlert {...prompt_alert_props}></PromptAlert> }
                <Paper sx={{padding : '3vh' , mt : '3vh' , maxWidth : 1300 , display : 'block', marginLeft : 'auto' , marginRight : 'auto' , height: 1000}}>
                    <Filters {...tbl_filter_props}></Filters>
                    <TableSSR {...tbl_props}></TableSSR>
                    <PagesComponent handleChangeRowsPerPage={handleChangeRowsPerPage} search={search} page={search.pages} rowsPerPage={search.offset} count={count} onPageChange={handleChangePage}></PagesComponent>
                </Paper>
                    <Bubble {...bubble_props}/>
                    {src !== '' &&
                        <Box sx={{ position: 'fixed', bottom: 0, left : 4, zIndex: 10000000, width: '100%' }}>
                            <ReactAudioPlayer src={src} style={{ width : (screenWidth - 50) + 'px' }} autoPlay controls={true} ref={audioRef} onPlay={handleAudioPlay} onPause={handleAudioPause}/> 
                            <IconButton onClick={() => handleAudioClose()}sx={{ position: 'absolute',top: 8,right: 8,backgroundColor: 'rgba(255, 255, 255, 0.8)', }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    }
            <ModalForm {...modal_props}></ModalForm>
        </ThemeProvider>
    )
}
export default Records