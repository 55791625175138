/*
 *
 * Filename: management.context.tsx
 * Date: 2022/12/14
 * Description: Main context set up
 * Author: Philippe Leroux @SKITSC
 *
 */

import React from "react";
import { i_user } from '../interface/user.interface';
import { i_domain } from "../interface/domain.interface";
import { i_smtp } from "../interface/smtp.interface";
import { default_user } from "../utility/constant";

//Context interface
export interface i_management_context {
    users : i_user [],
    smtp_config : i_smtp
    setNewSmtpConfig : ( new_config : i_smtp ) => void;
    loading : boolean,
    loading_two : boolean,
    setNewLoading_two : ( loading : boolean ) => void;
    selected_user : i_user
    domains : i_domain[],
    selected_domain : i_domain,
    setNewSelected_domain : ( new_selection : i_domain ) => void;
    setNewDomains : ( new_domains : i_domain[]) => void;
    setNewLoading : (CurrentLoaded : boolean) => void;    
    setNewUsers : ( new_users : i_user[]) => void;
    setNewSelected_user : ( new_user : i_user ) => void;
}
//Default Context State
export const ManagementDefaultContext : i_management_context = {
    users : [],
    smtp_config : { id : 0 , host : '' , pool : false , secure : false , username : '' , password : '' , from : '' , timestamp : 0},
    setNewSmtpConfig : ( new_config : i_smtp ) => {},
    loading : false,
    loading_two : false,
    setNewLoading_two : ( loading : boolean ) => {},
    domains : [],
    selected_user :  default_user,
    selected_domain : { id : 0 , address : '' , note : '' , timestamp : 0},
    setNewSelected_domain : ( new_selection : i_domain ) => {},
    setNewLoading : ( CurrentLoaded : boolean ) => {},
    setNewUsers : ( new_users : i_user[] ) => {},
    setNewSelected_user : ( new_user : i_user ) => {},
    setNewDomains : ( new_domains : i_domain[]) => {}
}

export const ManagementContext = React.createContext<i_management_context>(ManagementDefaultContext)