/*
 * Date: 2024
 * Description: Setting page of the app ( 'not done yet' )
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { Paper , Container , Typography , Button} from "@mui/material"
import { useContext, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';

//Custom themes
import { c_theme } from "../theme/custom.theme";

//Interfaces && types
import { i_alert_props, i_inital_props, i_modal_props, i_prompt_alert_props } from "../interface/utility.interface";

//Components
import ModalForm from "../components/modal/modal";
import PromptAlert from "../components/utils/prompt.alert";
import AdminPanel from "../components/setting/admin.panel";

//Constants
import { default_alert } from "../utility/constant";
//Context
import { MainContext } from "../context/main.context";
import { ManagementContext } from "../context/management.context";
import { ManagementHook } from "../context/management.hook";

const Setting = ( props : i_inital_props) => {
    const [ open, setOpen ] = useState<boolean>(false);
    const [ modalEvent , setEvent ] = useState<string>('')
    const { user } = useContext(MainContext)
    const [ alert_obj , setAlertObj ] = useState<i_alert_props>(default_alert);

    const hook = ManagementHook();

    const handleTransition = ( event : string ) => {
        setEvent(event)
        setAlertObj(default_alert)
        setOpen(true)
    }
    const modal_props : i_modal_props = {
        open : open,
        handleClose : () => setOpen(false),
        event : modalEvent,
        title : 'Change credentials',
        new_close : false,
        data : {},
        action : async() => {},
        loading : false,
        item_props : {}
    }
    const prompt_alert_props : i_prompt_alert_props = {
        ...alert_obj,
        reset : () => setAlertObj(default_alert)
    }
    return (
        <ThemeProvider theme={c_theme}>
            <ManagementContext.Provider value={hook}>
                {alert_obj.open ? <PromptAlert {...prompt_alert_props }></PromptAlert> : null }
                <Container sx={{ marginTop:5}}>
                    <>
                        {user.type !== 'Admin' ?
                            <Paper sx={{height:'80vh' , paddingBottom:'2vh' , paddingTop:'2vh' , paddingLeft:'2vw' , paddingRight:'2vw'}} elevation={4}>
                                <Typography sx={{ textAlign:'center' , fontSize:'24px' , marginBottom:'3vh'}}>Change your credentials</Typography>
                                    <Paper sx={{ padding:'3vh' , marginBottom:'3vh'}} elevation={5}>
                                        <Typography sx={{ marginLeft:'6vw',fontSize:'20px' ,display:'inline' , fontWeight:'800'}}>Your password : </Typography>
                                        <Typography sx={{ marginLeft:'6vw',fontSize:'24px' , display:'inline' , verticalAlign:'middle'}}>************</Typography>
                                        <Button onClick={() => handleTransition('password')} sx={{ float:'right' , fontWeight:'900' , display:'inline'}}variant="contained" color='primary'>Change password</Button>
                                    </Paper>
                                <Paper sx={{ padding:'3vh'}} elevation={5}>
                                    <Typography sx={{ marginLeft:'6vw',fontSize:'20px' ,display:'inline' , fontWeight:'800'}}>Your email : </Typography>
                                    <Typography sx={{ marginLeft:'6vw',fontSize:'24px' , display:'inline' , verticalAlign:'middle'}}>{user.email}</Typography>
                                    <Button onClick={() => handleTransition('email')} sx={{ float:'right' , fontWeight:'900' , display:'inline'}}variant="contained" color='primary'>Change your email</Button>
                                </Paper>   
                            </Paper>
                        :          
                            <AdminPanel {...prompt_alert_props}></AdminPanel>
                        }
                    </>
                   {open ? <ModalForm {...modal_props}></ModalForm> : null}
                   
                </Container>
            </ManagementContext.Provider>
        </ThemeProvider>
 
    )
}
export default Setting