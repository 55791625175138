/*
 * Date: 2025
 * Description: Page where the user can filters and navigate threw all logs..
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { ThemeProvider , Container , Typography , Paper , Box } from "@mui/material"
import { useState , useEffect  } from 'react'

//Components and utilities
import { Fetch } from "../components/api/fetch"
import LogsTable from "../components/table/logs.table"
import PagesComponent from "../components/table/fitler.table.pagination"
import { callers , types } from "../utility/constant"
import LogsFilters from "../components/table/top.bar.filter.logs"

//Interfaces && types
import { i_logs_search } from "../interface/logs.interface"
import { i_inital_props, i_log_table_props, i_logs_filter_props, i_promise, i_table_pagination_props } from "../interface/utility.interface"
import { i_logs } from "../interface/logs.interface"
import { t_log_output , t_log } from "../types/types"

//Themes
import { c_theme } from "../theme/custom.theme"


const Logs = ( props : i_inital_props ) => {
    const default_search : i_logs_search = {
        page : 0 , type : 'Any' , caller : 'Login' , offset : 25 , timestamp : 'DESC'
    }
    const [ data , setData ] = useState<i_logs[]>([])    
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ search , setSearch ] = useState<i_logs_search>( default_search )
    const [ count , setCount ] = useState<number>(0)
    const f_encodeQueryData = ( search : i_logs_search) =>  { return new URLSearchParams({...search, page : search.page.toString() , offset : search.offset.toString()}) }
    useEffect(() => {
        setLoading(true)
        const f_fetch_logs = async() => {
            const output : i_promise = await Fetch('/api/logs/filters?' + f_encodeQueryData( search ),null, 'GET')
            if(output.type === 'Success') {
                setData(output.data.logs)
                setCount(output.data.count)
            }
            setLoading(false)
        }
        f_fetch_logs();
    },[search , props])
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null, newPage: number) : void => { 
        const new_search : i_logs_search = {...search}
        new_search.page = newPage
        setSearch(new_search)
    };
    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_logs_search = {...search}
        new_search.page = 0
        new_search.offset = parseInt(event.target.value, 10)
        setSearch(new_search)
    };
    const handleTimestamp = () : void => {
        const current_search = {...search}
        if(search.timestamp === 'ASC'){
            current_search.timestamp = 'DESC'
            setSearch(current_search)
        }else{
            current_search.timestamp = 'ASC'
            setSearch(current_search)
        }
    }
    const handleChangeCaller = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_logs_search = {...search}
        if(callers.includes(event.target.value as t_log)){
            new_search.caller = event.target.value as t_log
            setSearch(new_search)
        }
    }
    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_logs_search = {...search}
        if(types.includes(event.target.value as t_log_output)){
            new_search.type = event.target.value as t_log_output
            setSearch(new_search)
        }
    }
    const clearFilter = () : void => setSearch(default_search)

    const log_filter_props : i_logs_filter_props = {
        search : search,
        clearFilter : clearFilter,
        handleTimestamp : handleTimestamp,
        handleChangeCaller : handleChangeCaller,
        handleChangeType : handleChangeType
    }
    const logs_table_props : i_log_table_props = {
        data : data,
        loading : loading,
        search : search,
        count : count
    }
    const pagination_props : i_table_pagination_props = {
        count: count,
        page: search.page,
        search : search,
        rowsPerPage: search.offset,
        onPageChange: handleChangePage,
        handleChangeRowsPerPage : handleChangeRowsPerPage,
    }
    return (
        <ThemeProvider theme={c_theme}>
                <Container sx={{ marginTop:5}}>
                        <Paper elevation={5} sx={{padding : '3vh' , mt : '3vh' ,height: 1080 }}>
                            <Typography sx={{ fontWeight: 800 , marginBottom : '1vh'}}>All Logs</Typography>
                            <Box>
                                <LogsFilters {...log_filter_props}></LogsFilters>
                                <LogsTable {...logs_table_props}></LogsTable>
                                <PagesComponent {...pagination_props}></PagesComponent>
                            </Box>
                        </Paper>
                </Container>
        </ThemeProvider>
    )
}
export default Logs