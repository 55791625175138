

import { i_management_context } from './management.context';
import { useState , useCallback , useMemo} from 'react'
import { i_user } from "../interface/user.interface";
import { i_domain } from '../interface/domain.interface';
import { i_smtp } from '../interface/smtp.interface';
import { default_user } from '../utility/constant';

export const ManagementHook = (): i_management_context =>{
    const [ users, setUsers] = useState<i_user[]>([]);
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ loading_two , setLoading_two ] = useState<boolean>(false)
    const [ domains , setDomains ] = useState<i_domain[]>([])
    const [ selected_domain , setSelected_domain ] = useState<i_domain>({ id : 0 , note : '' , address : '' , timestamp : 0})
    const [ selected_user , setSelected_user ] = useState<i_user>(default_user)
    const [ smtp_config , setSmtpConfig ] = useState<i_smtp>({ id : 0, host : 'Set me' , username : 'N/A' , password : 'N/A' , pool : false , secure : false , from : '' , timestamp : 0})
    const setNewUsers = useCallback(( new_users : i_user[] ) => {
        setUsers(new_users)
    }, [setUsers]);
    const setNewLoading = useCallback(( new_loading : boolean ) => {
            setLoading(new_loading)
    },[setLoading])
    const setNewSelected_user = useCallback(( new_user : i_user ) => {
        setSelected_user(new_user)
    },[setSelected_user])
    const setNewDomains = useCallback(( new_domains : i_domain[] ) => {
        setDomains(new_domains)
    },[setDomains])
    const setNewSelected_domain = useCallback(( new_domain : i_domain ) => {
        setSelected_domain(new_domain)
    },[setSelected_domain])
    const setNewLoading_two = useCallback(( loading : boolean) => {
        setLoading_two(loading)
    },[setLoading_two])

    const setNewSmtpConfig = useCallback(( new_config : i_smtp) => {
        setSmtpConfig(new_config)
    },[setSmtpConfig])

    return useMemo(() => ({
        users,
        loading,
        selected_user,
        domains,
        selected_domain,
        loading_two,
        smtp_config,
        setNewSmtpConfig,
        setNewLoading_two,
        setNewSelected_domain,
        setNewDomains,
        setNewSelected_user,   
        setNewUsers,
        setNewLoading
      }), [
        users,
        loading, 
        selected_user,
        domains,
        selected_domain,
        loading_two,
        smtp_config,
        setNewSmtpConfig,
        setNewLoading_two,
        setNewSelected_domain,
        setNewDomains,
        setNewSelected_user,   
        setNewUsers,
        setNewLoading
      ]);
  }
