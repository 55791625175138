/*
 * Date: 2025
 * Description: Filter bar for table records
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { Box , Typography , IconButton , TextField , InputLabel , FormControl , Select , MenuItem , InputAdornment ,ListSubheader  } from "@mui/material"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

//Icons
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";

//Utility functions
import { findEndpoint } from "../../utility/utility";

//Interfaces && types
import { i_tbl_filter_props } from "../../interface/utility.interface";


const RecordsFilter = ( props : i_tbl_filter_props) => {
    const S = props.search
    return(
        <Box display={'flex'} sx={{ height : 60}}>
            <Typography sx={{ alignSelf : 'center'}}>Index </Typography>
            <IconButton onClick={() => props.handleTimestamp()} sx={{ alignContent : 'center' , marginRight : '1vh'}}> {props.search.timestamp === 'ASC' ? <TrendingDownIcon/> : <TrendingUpIcon/> }</IconButton>
            <FormControl sx={{ width : '200px' , marginRight : '1vh'}} >
                <InputLabel id="search-select-label">From :</InputLabel>
                    <Select MenuProps={{ autoFocus: false }}labelId="Search-records"id="search-select" label="Options"
                    value={props.search.from_number} onChange={(e) =>  props.handleChangeFrom(e.target.value)} onClose={() => props.setSearchFrom("")} renderValue={() =>  findEndpoint(props.search.from_number , 'render' , props.endpoints )} >
                        <ListSubheader>
                            <TextField size="small" autoFocus placeholder="Type to search..." value={props.searchFrom} InputProps={{ startAdornment: ( <InputAdornment position="start" sx={{cursor : 'pointer'}} onClick={() => props.handleChangeFrom(props.searchFrom)}> <SearchIcon /></InputAdornment>) }} onChange={(e) => props.setSearchFrom(e.target.value)} onKeyDown={(e) => { if (e.key !== "Escape") { e.stopPropagation(); } }} />
                        </ListSubheader>
                        {props.displayedOptionsFrom.slice(0,20).map((option : any, i : number) => (
                            <MenuItem key={i} value={option.number}> {option.number} </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width : '200px' , marginRight : '1vh'}} >
                <InputLabel id="search-select-label">To :</InputLabel>
                    <Select MenuProps={{ autoFocus: false }}labelId="Search-records"id="search-select" label="Options"
                    value={props.search.to_number} onChange={(e) =>  props.handleChangeTo(e.target.value)} 
                    onClose={() => props.setSearchTo("")}renderValue={() => props.search.to_number} >
                        <ListSubheader>
                            <TextField size="small" autoFocus placeholder="Type to search..." value={props.searchTo} InputProps={{ startAdornment: ( <InputAdornment sx={{cursor : 'pointer'}} position="start"  onClick={() => props.handleChangeTo(props.searchTo)}> <SearchIcon /></InputAdornment>) }} onChange={(e) => props.setSearchTo(e.target.value)} onKeyDown={(e) => { if (e.key !== "Escape") { e.stopPropagation(); } }} />
                        </ListSubheader>
                        {props.displayedOptionsTo.slice(0,20).map((option : any, i : number) => (
                            <MenuItem key={i} value={option.number}> {option.number} </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField id={'search note'} sx={{ width : '200px' , marginRight : '1vh'}} value={props.search.note} onChange={(e) => props.handleChangeNote(e)} label="Note :"></TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ width : '200px'  , marginRight : '1vh'}} value={S.date_range.start !== 0 ? dayjs(S.date_range.start) : null} onChange={(e) => props.handleChangeDate(e ,"start")} label="Start date" />
                <DatePicker sx={{ width : '200px'}} label="End date" value={S.date_range.end !== 0 ? dayjs(S.date_range.end) : null} onChange={(e) => props.handleChangeDate(e ,"end")} />
            </LocalizationProvider>
            <IconButton sx={{ marginLeft : 'auto'}} onClick={() => props.clearFilter()}><ClearIcon/></IconButton>
        </Box>
    )
}

export default RecordsFilter