/*
 * Date: 2025
 * Description: Alert component to prompt user for new events.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { ReactElement } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//Interfaces
import { i_prompt_alert_props } from '../../interface/utility.interface';


const PromptAlert = (props : i_prompt_alert_props) : ReactElement => {
    const login_sx = { marginTop : '4vh', width : '35vh', position : 'absolute', right: '50%', left: '50%', transform: 'translate(-50%, -50%)' , zIndex : 100}
    const page_sx = { width : '35vh', position : 'absolute', marginLeft : '-2vh', right: '50%', left: '50%', transform: 'translate(-50%, -50%)' , zIndex : 100 }
	return (
		<>
			{props.open &&
				<Stack sx={props.position === 'page' ? page_sx : props.position === 'login' ? login_sx : {}} spacing={0}>
					<Alert severity={props.severity} onClose={props.reset}>{props.msg}</Alert>
				</Stack>
			}
		</>
	);
}
export default PromptAlert