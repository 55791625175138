/*
 * Date: 2024
 * Description: Email form logics
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useState , useContext, ReactElement } from 'react';
import { Paper , Box, Typography  } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Interfaces
import { i_promise , i_alert_props , i_error_input, i_prompt_alert_props } from '../../interface/utility.interface';

//Utils
import { f_generate_empty_promise } from '../../utility/utility';
import { Fetch } from '../api/fetch';

//Middlewares
import { m_valid_email } from '../utils/field.validation';

//Components
import PromptAlert from '../utils/prompt.alert';
import CustomInput from './custom.input';

//Context
import { MainContext } from '../../context/main.context';

//Styles
import { field_style } from '../styles/styles';

//Constants
import { default_alert } from '../../utility/constant';

const EmailForm = (props : any) : ReactElement => { 
    const { setUser , user } = useContext(MainContext)
    const [ email , setEmail] = useState<string>(user.email)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert_obj , setAlertObj ] = useState<i_alert_props>(default_alert);
    const [ err_email , setErremail] = useState<i_error_input>({error : false , msg : ''});
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        if(err_email) setErremail({error : false , msg : ''});
        handleAlertreset();
    }
    const handleAlertreset = () => {
        setAlertObj({msg : '' , severity : 'error' , open : false , position : 'modal'})
    }
    const handleSubmit = async( event : any) => {
        event.preventDefault();
        var output : i_promise = f_generate_empty_promise();
        const valid_email = m_valid_email(email);
        const data = {
            email : email,
            id : user.id
        }
        if(valid_email === ''){
            setLoading(true)
            output = await Fetch("/api/users/email", data , 'PATCH');
            setLoading(false)
            if(output.type === 'Success'){               
                props.setAlertObj({msg : output.message , severity : 'success' , open : true , position : 'page'})
                props.setAlert(true)
                props.handleClose(output)
                const new_user = user
                new_user.email = email
                setUser(new_user)
            }else{
                setEmail(user.email);
                setAlertObj({msg : output.message , severity : 'error' , open : true, position:'modal'})
            }
        }else{
            if(valid_email !== '') setErremail({ error : true , msg : valid_email})
        }
    }
    const prompt_alert_props : i_prompt_alert_props = {
        ...alert_obj,
        reset : () => setAlertObj(default_alert)
    }
    return (
        <Paper sx={{ padding:'1vh'}}>
            <Typography textAlign={'center'} sx={{fontWeight:'800', margin:'1vh'}}>Update your email</Typography>
            <Box component={'form'} onSubmit={handleSubmit}>
            {alert_obj.open ? <PromptAlert {...prompt_alert_props}></PromptAlert> : null}     
            <CustomInput value={email} onChange={handleChangeEmail} err={err_email} name={'email'} type={'email'} style={field_style} required={true} fullWidth={true} complete={'current-email'} ></CustomInput>      
                <LoadingButton type="submit" fullWidth variant="contained" color='primary' loading={loading} sx={{ mt: 3, mb: 2 }} >Submit</LoadingButton> 
            </Box>

        </Paper>
    )
}

export default EmailForm