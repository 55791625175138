/*
 * Date: 2024
 * Description: Form for user iterations
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useState , useEffect , useContext, ReactElement , ChangeEvent } from 'react';
import { Paper , Box, Typography , FormControl , FormLabel , Radio , FormControlLabel , RadioGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Interfaces
import { i_promise , i_alert_props , i_prompt_alert_props , i_modal_form_props } from '../../interface/utility.interface';
import { i_sent_user, i_user } from '../../interface/user.interface';

//Utils
import { f_generate_empty_promise, formatPhoneNumber } from '../../utility/utility';
import { Fetch } from '../api/fetch';

//Validation
import { m_valid_Cpassword, m_valid_email, m_valid_name, m_valid_password  , m_valid_phone} from '../utils/field.validation';

//Components
import PromptAlert from '../utils/prompt.alert';
import CustomInput from './custom.input';

//Styles
import { field_style } from '../styles/styles';

//Context
import { ManagementContext } from '../../context/management.context';
import { MainContext } from '../../context/main.context';
import { default_alert, default_user } from '../../utility/constant';


const UserForm = ( props : i_modal_form_props ) : ReactElement => { 
    const { selected_user } = useContext(ManagementContext)
    const { setUser , user } = useContext(MainContext)
    const [ confirm_password , setConfirmPassword ] = useState<string>('')
    const [ confirm_err , setConfirmError ] = useState<string>('')
    const [ form , setForm ] = useState<i_user>(default_user)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert_obj , setAlertObj ] = useState<i_alert_props>(default_alert);
    const [ error , setError ] = useState<i_user>(default_user)

    const handleField = (event: ChangeEvent<HTMLInputElement>) => {
        const { name , value } = event.target
        var custom_value = value
        if(name === 'confirm_password') setConfirmPassword(value)
        if(name.includes('phone')) custom_value = formatPhoneNumber(value)
        setForm({...form, [name] : custom_value })
    }

    const handleSubmit = async( event : any) => {
        event.preventDefault();
        var output : i_promise = f_generate_empty_promise();
        const valid_email = m_valid_email(form.email);
        var method : 'POST' | 'PUT' = form.id > 0 ? 'PUT' : 'POST'
        const data : i_sent_user = {
            id: form.id,
            email : undefined,
            password : undefined,
            first_name : undefined,
            last_name : undefined,
            phone : undefined,
            phone_two : undefined,
            type : form.type
        }
        if(valid_email !== '') return setError({ ...error, email : valid_email})
        data.email = form.email
        const handlePassword = () : boolean => {
            const valid_password = m_valid_password(form.password);
            const valid_Cpassword = m_valid_Cpassword(form.password , confirm_password)
            if(valid_password !== '') {
                setError({ ...error, password : valid_password})
                return false
            }
            if(valid_Cpassword !== '') {
                setConfirmError(valid_Cpassword)
                return false
            }
            return true
        }
        if(method === 'POST' || form.password !== ''){
            const ok = handlePassword()
            if(!ok) return;
            data.password = form.password
        }
        const valid_phone = m_valid_phone(form.phone)
        if(valid_phone !== '') return  setError({ ...error , phone : valid_phone})
        data.phone = form.phone      
        const valid_phone_two = m_valid_phone(form.phone_two)
        if(valid_phone !== '') return setError({ ...error , phone_two : valid_phone_two})
        data.phone_two = form.phone_two
        const valid_fn = m_valid_name(form.first_name)
        if(valid_fn !== '') return setError({ ...error , first_name : valid_fn})
        data.first_name = form.first_name
        const valid_ln = m_valid_name(form.last_name)
        if(valid_ln !== '') return setError({ ...error , last_name : valid_ln })
        data.last_name = form.last_name
        setLoading(true)
        output = await Fetch("/api/users", data , method);
        setLoading(false)
        if(output.type === 'Success'){    
            if(output.data.id === user.id && method === 'PUT') setUser(output.data)
            props.setAlertObj({msg : output.message , severity : 'success' , open : true, position : 'page'})
            props.handleClose()
            props.handleRerender('user')
        }else{
            setAlertObj({msg : output.message , severity : 'error' , open : true ,position:'modal'})
        }
    }
    useEffect(() => {
        setForm({...default_user,...selected_user })
    },[selected_user])
    useEffect(() => {
       if(error.phone && m_valid_phone(form.phone) === '') setError({...error, phone : ''})
        if(error.phone_two && m_valid_phone(form.phone_two) === '') setError({...error, phone_two : ''})
        if(error.first_name && m_valid_name(form.first_name) === '') setError({...error, first_name : ''})
        if(error.last_name && m_valid_name(form.last_name) === '') setError({...error, last_name : ''})
        if(error.password && m_valid_password(form.password) === '') setError({...error, password : ''})
        if(confirm_password !== '' && m_valid_Cpassword(form.password , confirm_password) === '') setConfirmError('')
    },[form , error , confirm_password])
    const prompt_alert_props : i_prompt_alert_props = {
        ...alert_obj,
        reset : () => setAlertObj(default_alert)
    }
    return (
        <Paper sx={{ padding:'1vh'}}>
            <Typography textAlign={'center'} sx={{fontWeight:'800', margin:'1vh'}}>{selected_user.id === 0 ? 'Create a new user' : 'Update user' }</Typography>
            <Box component={'form'} onSubmit={handleSubmit} autoComplete='off'>
                {alert_obj ? <PromptAlert {...prompt_alert_props}></PromptAlert> : null}     
                <CustomInput value={form.email} onChange={handleField} err={{ error : error.email !== '' ? true :false , msg : error.email}} name={'email'} type={'email'} style={field_style} required={true} fullWidth={true} complete={'current-email'} ></CustomInput>
                <CustomInput value={form.password} onChange={handleField}  err={{ error : error.password !== '' ? true :false , msg : error.password}} name={'password'} type={'password'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={confirm_password} onChange={handleField}  err={{ error : confirm_err !== '' ? true :false , msg : confirm_err}} name={'confirm_password'} type={'password'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <Typography>Optional fields **</Typography>
                <CustomInput value={form.phone} onChange={handleField}  err={{ error : error.phone !== '' ? true :false , msg : error.phone}} name={'phone'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={form.phone_two} onChange={handleField}  err={{ error : error.phone_two !== '' ? true :false , msg : error.phone_two}} name={'phone_two'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={form.first_name} onChange={handleField}  err={{ error : error.first_name !== '' ? true :false , msg : error.first_name}} name={'first_name'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={form.last_name} onChange={handleField}  err={{ error : error.last_name !== '' ? true :false , msg : error.last_name}} name={'last_name'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <FormControl>
                    <FormLabel>User type </FormLabel>
                        <RadioGroup value={form.type} name={'type'} onChange={handleField} sx={{ display : 'flex'}}>
                            <FormControlLabel value="Admin" control={<Radio />} label={'Admin'} />
                            <FormControlLabel value="Worker" control={<Radio />} label={'Worker'} />
                        </RadioGroup>
                </FormControl>
                <LoadingButton type="submit" fullWidth variant="contained" color='primary' loading={loading} sx={{ mt: 3, mb: 2 }} >Submit</LoadingButton> 
            </Box>

        </Paper>
    )
}

export default UserForm