/*
 * Date: 2025
 * Description: Hook for main context
 * Author: Philippe Leroux @SKITSC
 *
 */

import { useState , useCallback } from 'react';
import { i_user } from '../interface/user.interface';
import { i_main_context } from './main.context';
import { default_user } from '../utility/constant';

//Main hook for the initial context , callback for states
export const useHook = () : i_main_context => {
    const [ authenticated , setAuthenticated ] = useState(false)
    const [ user , setUserr ] = useState<i_user>(default_user)
    const [ loaded , setLoad ] = useState(false)
    const setCurrentAuth = useCallback((currentAuth : boolean): void => {
        setAuthenticated(currentAuth)
    },[])

    const setUser = useCallback((currentUser : i_user): void => {
        setUserr({first_name : currentUser.first_name , last_name : currentUser.last_name , id : currentUser.id , UUID : currentUser.UUID , type : currentUser.type , password : '' , email : currentUser.email , phone : currentUser.phone, phone_two : currentUser.phone_two})
    },[])
    const setLoaded = useCallback((currentload : boolean) : void => {
        setLoad(currentload)
    },[])
    const HandleLogout = () => {
        setCurrentAuth(false)
        setUser(default_user)
    }
    const HandleLogin = ( user: i_user ) => {
        setUser(user)
        setCurrentAuth(true)
    }
    return { authenticated, loaded, user, setLoaded, setCurrentAuth, setUser, HandleLogin, HandleLogout, }

}