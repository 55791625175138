/*
 *
 * Filename: app.tsx
 * Date: 2022-11-03
 * Description: Main component , where routes and context , props are initialized. Also checking if a token is serve.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Routes, Navigate , Route , HashRouter } from "react-router-dom";
import { useState , useEffect , useContext } from 'react'

//Styles
import './styles/main.css';

//Contexts
import { MainContext } from './context/main.context';
import { useHook } from './context/main.hook';

import { SocketProvider , SocketContext } from './context/socket.context';

//Utilities
import { f_logout, Fetch } from './components/api/fetch';

//Interfaces
import { i_inital_props, i_promise } from "./interface/utility.interface";

//Pages
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import OfflineBox from './pages/offline';
import Setting from './pages/setting';
import Welcome from './pages/welcome';
import Logs from './pages/logs';
import ForgotPassword from './pages/forgotpassword';
import Records from "./pages/records";
import Logout from './pages/logout';
import Endpoints from './pages/endpoints';

//Menu component
import Menu from './components/menu/menu';





function App() {
    const ContextState = useHook();
    const [offline , setOffline] = useState<boolean>(false)   
    const [ loaded , setLoaded ] = useState<boolean>(false) 
    const socket = useContext(SocketContext);
    const useAuth = () => {
        if (ContextState.authenticated) {
            return true
        } else {
            return false
        }
    }        
    const f_auth_check = async() =>{
        const output : i_promise = await Fetch('/api/auth' ,null , 'POST')
        if(output.type === 'Error') {
            setOffline(true)
        }else{
            setOffline(false)
        }
        setLoaded(true)
        return output
    }
    useEffect(() => {
        const f_fetch_handler = async () => {
            const api_rep : i_promise = await f_auth_check();
            if (api_rep.type === 'Success') {
                ContextState.HandleLogin(api_rep.data);
                socket.emit('join', api_rep.data.type)
            }
            ContextState.setLoaded(true);
        }
        f_fetch_handler() //eslint-disable-next-line react-hooks/exhaustive-deps
    },[ContextState.authenticated])
    useEffect(() => {
		if (!socket.connected && ContextState.authenticated) {
			socket.connect();
            
			const checkJwt = () => {
				socket.emit("check-jwt");
			};
			const handleConnect = () => {
				const socketInterval = setInterval(checkJwt, 60000);
				socket.on("logout", async() => {
                    console.log("User logged out");
					clearInterval(socketInterval);
                    await f_logout()
                    socket.disconnect()
					ContextState.setCurrentAuth(false)
				});
			};

			socket.once("connect", handleConnect);

			return () => {
				socket.off("connect", handleConnect);
			};
		}
         //eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ContextState.authenticated]);
    const PrivateRoute = ({ children }: any) => {
        const auth = useAuth();
        return auth ? children : <Navigate to="/" />;
    }
    const ProtectedRoute = ({ children }: any) => {
        const auth = useAuth();
        return (auth && ContextState.user.type === 'Admin' ) ? children : <Navigate to="/login" />;
    }
    const props : i_inital_props = {
        logout : ContextState.HandleLogout,
        loaded : loaded,
        offline : offline
    }
  return (
    <div className="app">
        <MainContext.Provider value={ContextState}>
            <SocketProvider>
                <HashRouter>
                        <Routes>
                            <>
                                <Route path='/' element={<Welcome {...props}/>}></Route>
                                <Route path="/login" element={<Login {...props} />} />
                                <Route path="/dashboard" element={<ProtectedRoute><Menu {...ContextState} MyComponent={<Dashboard {...props}/>}/></ProtectedRoute>} />
                                <Route path="/records" element={<PrivateRoute><Menu {...ContextState} MyComponent={<Records {...props}/>}/> </PrivateRoute>} />
                                <Route path="/endpoints" element={<ProtectedRoute><Menu  {...ContextState} MyComponent={<Endpoints {...props}/>}/></ProtectedRoute>} />
                                <Route path="/setting" element={<ProtectedRoute><Menu  {...ContextState} MyComponent={<Setting {...props} />}/> </ProtectedRoute>} />
                                <Route path="/logs" element={<ProtectedRoute><Menu  {...ContextState} MyComponent={<Logs {...props}/>}/> </ProtectedRoute>} />
                                <Route path='/logout' element={<PrivateRoute><Logout  {...props}></Logout></PrivateRoute>} />
                                <Route path='/offline' element={<OfflineBox offline={offline} />}></Route>
                                <Route path='/forgotpassword' element={<ForgotPassword {...props} />}></Route>
                            </>
                        </Routes>
                    </HashRouter>
                </SocketProvider>
            </MainContext.Provider>
    </div>
  );
}

export default App;
